import {LogLevel} from '../types'
import {ResourcesConfig} from 'aws-amplify'

export function getAwsConfig(): ResourcesConfig {
  return {
    Auth: {
      Cognito: {
        //  Amazon Cognito User Pool ID
        userPoolId: process.env.COGNITO_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: process.env.COGNITO_WEB_CLIENT_ID,
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
        // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
        allowGuestAccess: true,
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'code' // 'code' | 'link',
        // loginWith: {
        //   // OPTIONAL - Hosted UI configuration
        //   oauth: {
        //     domain: process.env.OAUTH_DOMAIN,
        //     scopes: ['openid', 'aws.cognito.signin.user.admin', 'profile'],
        //     redirectSignIn: process.env.REDIRECT_SIGN_IN.split(','),
        //     redirectSignOut: process.env.REDIRECT_SIGN_OUT.split(','),
        //     responseType: 'code'
        //   }
        // }
      }
    },
    Storage: {
      S3: {
        region: process.env.S3_PUBLIC_ASSETS_BUCKET_REGION, // (required) - Amazon S3 bucket region
        bucket: process.env.S3_PUBLIC_ASSETS_BUCKET // (required) - Amazon S3 bucket URI
      }
    }
  }
}

declare var process: {
  env: {
    [key: string]: string
  }
}

export interface AppConfig {
  adyenClientId: string
  adyenEnvironment: string
  cognitoRegion: string
  cognitoUserPoolId: string
  cognitoWebClientId: string
  environment: string
  guestCheckinApiUrl: string
  guestPortalApiUrl: string
  guestTokenIssuer: string
  loggingApiUrl: string
  loggingEnabled: boolean
  loggingLevel: LogLevel
  metricsApiUrl: string
  metricsEnabled: boolean
  metricsNamespace: string
  reduxDevTools: boolean
}

export function getConfig(): AppConfig {
  return {
    adyenClientId: process.env.ADYEN_CLIENT_ID,
    adyenEnvironment: process.env.ADYEN_ENVIRONMENT,
    cognitoRegion: process.env.COGNITO_REGION,
    cognitoUserPoolId: process.env.COGNITO_USER_POOL_ID,
    cognitoWebClientId: process.env.COGNITO_WEB_CLIENT_ID,
    environment: process.env.ENVIRONMENT,
    guestCheckinApiUrl: process.env.GUEST_CHECKIN_API_URL,
    guestPortalApiUrl: process.env.GUEST_PORTAL_API_URL,
    guestTokenIssuer: process.env.GUEST_TOKEN_ISSUER,
    loggingApiUrl: process.env.LOGGING_API_URL,
    loggingEnabled: process.env.LOGGING_ENABLED === 'true',
    loggingLevel: process.env.LOGGING_LEVEL as LogLevel,
    metricsApiUrl: process.env.METRICS_API_URL,
    metricsEnabled: process.env.METRICS_ENABLED === 'true',
    metricsNamespace: process.env.METRICS_NAMESPACE,
    reduxDevTools: process.env.REDUX_DEV_TOOLS === 'true'
  }
}
