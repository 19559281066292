import React from 'react'
import {Button, Modal} from 'react-daisyui'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faUser} from '@fortawesome/free-solid-svg-icons'

interface ManageReservationProps {
  // reservation: Reservation
  reservation: {
    cancellationPolicy: string
  }
}

const CancellationPolicy = ({reservation}: ManageReservationProps) => {
  const {Dialog, handleShow} = Modal.useDialog()

  return (
    <div className="">
      <Dialog>
        <Modal.Header className="font-bold">Cancellation Policy</Modal.Header>
        <Modal.Body>{reservation.cancellationPolicy}</Modal.Body>
        <Modal.Actions>
          <form method="dialog">
            <Button>Close</Button>
          </form>
        </Modal.Actions>
      </Dialog>
      <div className="pt-4 cursor-pointer" onClick={handleShow}>
        <a className="text-cabana">
          <FontAwesomeIcon
            icon={faEye}
            color="cabana"
            className="text-xs pr-2 text-cabana"
          />
          View cancellation policy
        </a>
      </div>
    </div>
  )
}

export default CancellationPolicy
