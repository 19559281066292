import React from 'react'
import {createRoot} from 'react-dom/client'
import App from './components/App'
import {getAwsConfig} from './services/config'

import {HashRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import AppErrorBoundary from './components/AppErrorBoundary'
import {store} from './store/store'
import {Amplify} from 'aws-amplify'

import './index.css'
import './types'

const container = document.getElementById('appRoot')
const root = createRoot(container!)

const awsConfig = getAwsConfig()

console.log('CONFIG', awsConfig)

Amplify.configure(awsConfig)

root.render(
  <AppErrorBoundary>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </AppErrorBoundary>
)
