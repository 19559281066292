import React, {useState} from 'react'
import {Amplify} from 'aws-amplify'
import {fetchAuthSession, signIn, signOut} from 'aws-amplify/auth'
import {getConfig} from '../../services/config'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import {getLogger} from '../../services/logging'
import {UserRole} from '../../types'
import ResetPasswordCode from '../account/ResetPasswordCode'
import {useNavigate} from 'react-router-dom'

const logger = getLogger('MemberLoginForm')

const MemberLoginForm = () => {
  const navigate = useNavigate()
  const onResetSuccess = () => {
    navigate('/login')
  }

  return (
    <>
      <ResetPasswordCode />
    </>
  )
}

export default MemberLoginForm
