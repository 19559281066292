import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {removeUserToken} from '../../services/token'
import {useAppDispatch} from '../../store/hooks'
import {clearUser} from '../../store/userSlice'
import {getLogger} from '../../services/logging'
import {signOut} from 'aws-amplify/auth'

const logger = getLogger('LogoutPage')

const LogoutPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(async () => {
      await signOut()
      logger.info({message: 'Logout successful'})
      dispatch(clearUser())
      removeUserToken()
      navigate('/')
    }, 1000)
  }, [])

  return (
    <div className="min-h-screen flex justify-center items-center gap-2">
      <LoadingSpinner size={LoadingSpinnerSize.Large} />
      <div>Logging out...</div>
    </div>
  )
}

export default LogoutPage
