import React, {useCallback, useRef} from 'react'
import {Accordion, Breadcrumbs, Button, Modal} from 'react-daisyui'
import {formatEndDate, formatStartDate} from '../../services/utils'
import {useCancelReservationMutation} from '../../services/guestPortalApi'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faUser} from '@fortawesome/free-solid-svg-icons'
import MemberUpdatePayment from './MemberUpdatePayment'
import StayCard from './StayCard'
import CancellationPolicy from './CancellationPolicy'

interface ManageReservationProps {
  // reservation: Reservation
  reservation: {
    cancellationPolicy: string
    confirmationCode: string
    firstName: string
    lastName: string
    email: string
    phoneLast4: string
    propertyId: string
    bookingId: string
    reservationId: string
    checkinDate: string
    checkoutDate: string
    unitType: string
    reservation_status: string
    propertyTitle: string
    propertyPhone: string
    propertySMSPhone: string
    propertyAddress: string
    preCheckUrl: string
    pmStayInfoLink: string
  }
  property?: {
    apaleoId: string
    name: string
    cancellationPolicy?: string
  }
  onMyStaysClick?: Function
}

const ManageReservation = ({
  reservation,
  property,
  onMyStaysClick
}: ManageReservationProps) => {
  const [
    cancelReservation,
    {data, error, isLoading}
  ] = useCancelReservationMutation()

  const handleCancelClick = () => {
    cancelReservation(reservation.reservationId)
  }

  const args = {icon: 'arrow'}

  const {Dialog, handleShow} = Modal.useDialog()

  return (
    <div className="">
      <Dialog>
        <Modal.Header className="font-bold">Confirm Cancel</Modal.Header>
        <Modal.Body>Canceling reservation cannot be undone!</Modal.Body>
        <Modal.Actions>
          <form method="dialog">
            <Button>Close</Button>
            <Button type="submit" onClick={handleCancelClick}>
              Confirm
            </Button>
          </form>
        </Modal.Actions>
      </Dialog>
      <div className="breadcrumbs text-sm">
        <ul>
          <li>
            <a
              onClick={evt => {
                if (onMyStaysClick) {
                  onMyStaysClick()
                }
              }}
            >
              My Stays{' '}
            </a>
          </li>
          {' > '} {formatStartDate(reservation.checkinDate)} -{' '}
          {formatEndDate(reservation.checkoutDate)}
        </ul>
      </div>
      <Accordion icon="arrow" defaultChecked>
        <Accordion.Title className="text-xl font-bold border-b-2 pb-0 pl-2 collapse-arrow">
          Manage Reservation
        </Accordion.Title>
        <Accordion.Content className="pt-2">
          <div className="container mx-auto space-y-6">
            {reservation.reservation_status !== 'Canceled' ? (
              <div>
                <div className="container space-y-2">
                  <h2 className="font-semibold text-lg">
                    Prepare for your stay
                  </h2>
                  <p className="pb-4">
                    Get directions, property information, local recommendations
                    and more with your personalized check-in page.
                  </p>
                  <a
                    href={`${reservation.pmStayInfoLink}&code=${reservation.confirmationCode}`}
                    target="_blank"
                    className="p-y-4"
                  >
                    <Button color="secondary" className="btn-md">
                      view check-in page
                    </Button>
                  </a>
                </div>
                <div className="container space-y-2">
                  <h2 className="font-semibold text-lg">
                    Cancel your reservation
                  </h2>
                  <p>
                    You can cancel your reservation if you booked with us
                    directly. If you used an online travel agent like Expedia,
                    Booking.com or AirBnb, you need to cancel your reservation
                    with them.
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}
            <p className="font-semibold text-lg  max-w-lg">
              Reservation:{' '}
              <span className="font-light">{reservation.reservationId}</span>
            </p>
            <div className="grid grid-cols-2">
              <div className="pt-2">
                <p className="font-semibold uppercase text-sm">Property:</p>
                <p>{reservation.propertyTitle}</p>
              </div>
              <div className="pt-2">
                <p className="font-semibold uppercase text-sm">Unit Type:</p>
                <p>{reservation.unitType}</p>
              </div>
            </div>
            <div className="pt-2 grid grid-cols-3">
              <div className="">
                <p className="font-semibold uppercase text-sm">CHECK-IN:</p>
                <p>{formatEndDate(reservation.checkinDate)}</p>
              </div>
              <div className="">
                <p className="font-semibold uppercase text-sm">CHECK-OUT:</p>
                <p>{formatEndDate(reservation.checkoutDate)}</p>
              </div>

              <div className="">
                <p className="font-semibold uppercase text-sm">STATUS:</p>
                <p>{reservation.reservation_status}</p>
              </div>
            </div>
          </div>
          {reservation.reservation_status !== 'Canceled' ? (
            <div className="pt-4">
              <Button
                color="secondary"
                className="btn-md btn-outline"
                onClick={handleShow}
              >
                cancel reservation
              </Button>
            </div>
          ) : (
            ''
          )}

          <div className="pt-4">
            Need to do something else? You can email us at info@placemakr.com or
            call {reservation.propertyTitle} at {reservation.propertyPhone}.
          </div>
          <CancellationPolicy reservation={reservation} />
        </Accordion.Content>
      </Accordion>
      <Accordion icon="arrow">
        <Accordion.Title className="text-xl font-bold border-b-2 pb-0 pl-2">
          Update Payment
        </Accordion.Title>
        <Accordion.Content>
          <div className="">
            <p className="py-4">
              Update a payment method to save to your reservation. Your payment
              method will not be charged at this time. If your reservation
              wasn’t prepaid, we will run your payment 1 day before check-in.
            </p>
            <MemberUpdatePayment reservation={reservation} />
            <p className="py-4">
              Need to do something else? You can email us at info@placemakr.com
              or call {reservation.propertyTitle} at {}.
            </p>
          </div>
        </Accordion.Content>
      </Accordion>
      <Accordion icon="arrow">
        <Accordion.Title className="text-xl font-bold border-b-2 pb-0 pl-2">
          Get assistance
        </Accordion.Title>
        <Accordion.Content>
          <p>Need a hand with something else? We're here for you 24/7.</p>
          <ul>
            <li>
              <p>
                <span className="pr-2">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="text-xs text-primary"
                  />
                </span>
                Call at {reservation.propertyPhone}
              </p>
            </li>
            <li>
              <span className="pr-2">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-xs text-primary"
                />
              </span>
              Text us at {reservation.propertyPhone}
            </li>
            <li>
              <span className="pr-2">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-xs text-primary"
                />
              </span>
              Email us at info@placemakr.com
            </li>
          </ul>
        </Accordion.Content>
      </Accordion>
    </div>
  )
}

export default ManageReservation
