import 'react-phone-number-input/style.css'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useAppSelector} from '../../store/hooks'
import {selectUser} from '../../store/userSlice'
import {getAsYouType, parsePhoneNumber} from 'awesome-phonenumber'
import {PhoneNumberField} from '@aws-amplify/ui-react'

import {useUpdateUserAttributesMutation} from '../../services/cognitoApi'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {Button, Checkbox, Input, Modal} from 'react-daisyui'
// import PhoneInput from 'react-phone-number-input'
import ResetPassword from './ResetPassword'
import VerifyUserAttribute from './VerifyUserAttribute'
import NotificationPreferences from './NotificationPreferences'
import ResetPasswordCode from './ResetPasswordCode'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {useForm} from 'react-hook-form'
import {updateUserAttributes} from 'aws-amplify/auth'
import PhoneInput from 'react-phone-number-input/react-hook-form-input'
import metadata from 'libphonenumber-js/metadata.min.json'

interface EditProfileProps {}

const handleSuccess = (navigate: Function) => () => {
  alert('password is successfully changed!')
}

export const EditProfile = () => {
  const user = useAppSelector(selectUser)
  const [isUpdating, setIsUpdating] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: {errors, isDirty},
    watch
  } = useForm({
    defaultValues: {
      given_name: user.firstName,
      family_name: user.lastName,
      email: user.email,
      phone_number: user.phoneNumber
    }
  })

  const userAttributes = watch()

  console.log('user:', user)

  const phoneNumberRef = React.useRef(null)
  const dialCodeRef = React.useRef(null)

  const parsedPhoneNumber = parsePhoneNumber(user.phoneNumber)
  const [countryCode, setCountryCode] = useState(
    `+${parsedPhoneNumber.countryCode}`
  )
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(
    parsedPhoneNumber
  )

  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [showVerifyPhone, setShowVerifyPhone] = useState(false)
  const [showVerifyEmail, setShowVerifyEmail] = useState(false)

  const [verification, setVerification] = useState({
    emailVerified: user.emailVerified,
    phoneVerified: user.phoneNumberVerified
  })

  const [editting, setEditting] = useState(false)

  interface UserAttributes {
    family_name: string
    given_name: string
    email: string
    phone_number: string
  }

  interface Verification {
    emailVerified: boolean
    phoneVerified: boolean
  }

  const handleEditProfile = async (data: any) => {
    setEditting(false)
    setIsUpdating(true)
    try {
      const response = await updateUserAttributes({userAttributes: data})
      reset(data)
    } catch (error) {
      console.error('Error updating profile:', error)
      setErrorMessage(error.message)
    } finally {
      setIsUpdating(false)
    }
  }

  const {Dialog, handleShow} = Modal.useDialog()

  const [showResetPwdDialog, setShowResetPwdDialog] = useState(false)

  const [modalType, setModalType] = useState('')

  type ModalType = 'resetPassword' | 'verifyEmail' | 'verifyPhone'

  const handleShowModal = (type: ModalType) => {
    console.log('showing modal:', type)
    handleShow()
    setModalType(type)
  }

  const handleCancelEdit = () => {
    reset()
    setEditting(false)
  }

  return (
    <div className="container">
      <Dialog
        open={showVerifyPhone}
        onClose={() => setShowVerifyPhone(false)}
        className="modal-box bg-white max-w-sm"
      >
        <VerifyUserAttribute
          attributeName="Phone Number"
          attribute="phone_number"
          attributeValue={watch('phone_number')}
        ></VerifyUserAttribute>
        <Modal.Actions></Modal.Actions>
      </Dialog>
      <Dialog
        open={showVerifyEmail}
        onClose={() => setShowVerifyEmail(false)}
        className="modal-box bg-white max-w-sm"
      >
        <VerifyUserAttribute
          attributeName="Email"
          attribute="email"
          attributeValue={watch('email')}
        ></VerifyUserAttribute>
      </Dialog>
      <Dialog
        open={showResetPwdDialog}
        onClose={() => setShowResetPwdDialog(false)}
        className="modal-box bg-white max-w-sm"
      >
        <ResetPassword></ResetPassword>
        <form method="dialog">
          <Button className="btn btn-link no-underline text-sm w-full">
            cancel
          </Button>
        </form>
        {/* <ResetPasswordCode username={user.email}></ResetPasswordCode> */}
      </Dialog>
      <div className="grid grid-flow-row gap-8">
        <h1 className="text-xl font-semibold">Edit my info</h1>
        <p className="text-sm font-light">
          Update your profile information here.
        </p>
        <form
          onSubmit={handleSubmit(handleEditProfile)}
          onReset={handleCancelEdit}
          className="form-control grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 max-w-2xl"
        >
          <div>
            <label className="label pt-6 pb-1">
              <span className="label-text">First Name</span>
            </label>
            <Input
              id="first_name"
              name="first_name"
              className="border-1 border-slate-200 bg-white w-full"
              required
              {...register('given_name', {required: true})}
            />
          </div>

          <div>
            <label className="label pt-6 pb-1">
              <span className="label-text">Last Name</span>
            </label>
            <Input
              id="family_name"
              name="family_name"
              required
              className="border-1 border-slate-200 bg-white w-full"
              {...register('family_name', {required: true})}
            />
          </div>

          <div>
            <label className="label pb-1">
              <span className="label-text">Phone Number</span>
              <span className="label-text-alt">
                {verification.phoneVerified ? (
                  'Verified'
                ) : (
                  <div
                    className="btn btn-link btn-xs no-underline text-xs text-red-800"
                    onClick={() => setShowVerifyPhone(true)}
                  >
                    Verify
                  </div>
                )}
              </span>
            </label>
            <PhoneInput
              placeholder="Enter phone number"
              // layout="second"
              metadata={metadata}
              name="phone_number"
              control={control}
              // metadata={{country: 'US'}}
            />
          </div>

          <div>
            <label className="label pb-1">
              <span className="label-text">Email</span>
              <span className="label-text-alt">
                {verification.emailVerified ? (
                  'Verified'
                ) : (
                  <div
                    className="btn btn-xs btn-link no-underline text-sm text-red-800"
                    onClick={() => setShowVerifyEmail(true)}
                  >
                    Verify
                  </div>
                )}
              </span>
            </label>
            <Input
              id="email"
              name="email"
              required
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              className="border-1 border-slate-200 bg-white w-full"
              {...register('email', {required: true})}
            />
          </div>

          <div>
            <label className="label pt-6 pb-1">
              <span className="label-text">Password</span>
            </label>
            <Input
              id="password"
              name="password"
              type="password"
              disabled={true}
              value={'********'}
              required
              className="border-1 border-slate-200 bg-white w-full"
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              //   setUserAttributes({
              //     ...userAttributes,
              //     family_name: e.target.value
              //   })
              // }
            />
            <div className="pt-4 cursor-pointer">
              <a
                className="text-cabana"
                onClick={e => {
                  setShowResetPwdDialog(true)
                }}
              >
                {/* <FontAwesomeIcon
            icon={faEye}
            color="cabana"
            className="text-xs pr-2 text-cabana"
          /> */}
                Reset Password
              </a>
              {/* {showPasswordReset ? <ResetPassword></ResetPassword> : ''} */}
            </div>
          </div>
          <div></div>
          <div className="col-span-2">
            {isUpdating ? (
              <LoadingSpinner size={LoadingSpinnerSize.Large} />
            ) : (
              ''
            )}
            {isDirty ? (
              <div>
                <Button
                  className="btn btn-link no-underline"
                  type="reset"
                  onClick={e => handleCancelEdit()}
                >
                  cancel
                </Button>
                <Button className="btn btn-secondary" type="submit">
                  Save
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </form>

        <NotificationPreferences />
      </div>
    </div>
  )
}
