import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export function formatDate(d: Date): string {
  try {
    return format(d, 'MMM d, yyyy')
  } catch (e) {
    console.warn('Failed to format date', d)
    return ''
  }
}

export function formatDateString(s: string): string {
  try {
    return formatDate(parseISO(s))
  } catch (e) {
    console.warn('Failed to format date string', s)
    return ''
  }
}

export const formatEndDate = (dateString: string) => {
  const date = new Date(dateString)
  date.setFullYear(2025)
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

export const formatStartDate = (dateString: string) => {
  const date = new Date(dateString)
  date.setFullYear(2025)
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric'
  })
}
