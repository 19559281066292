import React from 'react'
import {Accordion, Badge, Button, Card, Tabs} from 'react-daisyui'
import {useMemberContext} from './MemberPage'
import {EditProfile} from '../account/EditProfile'
import StayCard from '../stays/StayCard'
import MemberStays from '../stays/MemberStays'

const MemberDashboardPage = ({defaultTab}: {defaultTab: string}) => {
  const {user} = useMemberContext()

  return (
    <div>
      <p className="py-8 text-3xl font-normal">
        Hi, <span className="font-bold"> {user && user.firstName}</span>
      </p>
      <div className="p-0 flex flex-col gap-4 shadow-md">
        <div role="tablist" className="tabs tabs-bordered  border-cabana">
          <input
            id="my_stays_tab"
            type="radio"
            name="memberdashboard_tabs"
            role="tab"
            className="tab tab-input-1 px-14 pt-4 pb-10 text-lg"
            defaultChecked={defaultTab === 'stays'}
          />
          <div role="tabpanel" className="tab-content p-10">
            <MemberStays />
          </div>
          <label
            role="button"
            htmlFor="my_stays_tab"
            className="tab-label tab-label-1 px-12 py-4"
          >
            <p>
              My <span className="text-md font-semibold">Stays</span>
            </p>
          </label>

          <input
            id="my_info_tab"
            type="radio"
            name="memberdashboard_tabs"
            role="tab"
            className="tab tab-input-2 px-14 pt-4 pb-10 text-lg"
            defaultChecked={defaultTab === 'profile'}
          />
          <div role="tabpanel" className="tab-content p-10">
            <EditProfile></EditProfile>
          </div>
          <label
            role="button"
            htmlFor="my_info_tab"
            className="tab-label tab-label-2 px-12 py-4"
          >
            <p>
              {' '}
              My <span className="text-md font-semibold">Info</span>
            </p>
          </label>
        </div>
      </div>
    </div>
  )
}

export default MemberDashboardPage
