import React, {useState} from 'react'
import {useUpdatePasswordMutation} from '../../services/cognitoApi'
import {Button, Input} from 'react-daisyui'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'

export const ResetPassword = () => {
  const [
    updatePasswordMutation,
    updatePasswordMutationResult
  ] = useUpdatePasswordMutation()
  return (
    <div>
      <h1 className="font-semibold text-xl">Reset Password</h1>
      {updatePasswordMutationResult.isUninitialized ? (
        <form
          className="form-control "
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            console.log(e)
            const target = e.target as HTMLFormElement
            updatePasswordMutation({
              oldPassword: (target.elements as any).oldPassword.value,
              newPassword: (target.elements as any).newPassword.value
            })
          }}
        >
          <div className="grid grid-cols-1 gap-2">
            <div>
              <label className="label pb-1 text-sm font-light">
                Current Password
              </label>
              <Input
                name="oldPassword"
                type="password"
                placeholder="Enter your current password"
                className="border-1 border-cabana bg-white w-full"
              ></Input>
            </div>

            <div>
              <label className="label pb-1 text-sm font-light">
                New Password
              </label>

              <Input
                name="newPassword"
                type="password"
                placeholder="Enter your new password"
                className="border-1 border-cabana bg-white w-full"
              ></Input>
            </div>
            <div>
              <label className="label pb-1 text-sm font-light">
                Confirm Password
              </label>

              <Input
                name="confirmPassword"
                type="password"
                placeholder="Confirm your new password"
                className="border-1 border-cabana bg-white w-full"
              ></Input>
            </div>
            {/* <div className="text-red-500">Passwords do not match!</div> */}
            <Button
              className="mt-6 btn-md text-lg font-semibold lowercase w-full"
              color="secondary"
              type="submit"
            >
              Reset Password
            </Button>
          </div>
        </form>
      ) : (
        ''
      )}

      {updatePasswordMutationResult.error ? (
        <div>Error Updating Password</div>
      ) : (
        ''
      )}
      {updatePasswordMutationResult.isLoading ? (
        <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
      ) : (
        ''
      )}
      {updatePasswordMutationResult.isSuccess ? (
        <div>Password Updated</div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ResetPassword
