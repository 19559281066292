import React, {useState} from 'react'
import {Button, Input} from 'react-daisyui'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'
import {useForm} from 'react-hook-form'
import {signUp} from 'aws-amplify/auth'

export const CreateMemberForm = ({
  onSignUpSuccess
}: {
  onSignUpSuccess?: Function
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch
  } = useForm()
  const [isSigningUp, setIsSigningUp] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleSignUp = async (data: any) => {
    event.preventDefault()
    setIsSigningUp(true)
    try {
      const {nextStep} = await signUp({
        username: data.email,
        password: data.password,
        options: {
          userAttributes: {
            given_name: data.given_name,
            phone_number: data.phone_number,
            family_name: data.family_name
          },
          autoSignIn: true
        }
      })
      if (onSignUpSuccess) {
        onSignUpSuccess({nextStep, data})
      }
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsSigningUp(false)
    }
  }

  return (
    <div>
      {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
      <form onSubmit={handleSubmit(handleSignUp)}>
        <div>
          <label className="label pb-1">Email</label>
          <Input
            {...register('email', {required: true, pattern: /^\S+@\S+$/i})}
            type="email"
            name="email"
            className="border-1 border-slate-200 bg-white w-full"
            required
          />
          {errors.email?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Email is required
            </p>
          )}
          {errors.email?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid email address
            </p>
          )}
        </div>
        <div>
          <label className="label pb-1">Phone Number:</label>
          <Input
            {...register('phone_number', {required: true})}
            className="border-1 border-slate-200 bg-white w-full"
            type="tel"
            name="phone_number"
          />
          {errors.phone_number?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Phone number is required
            </p>
          )}
          {errors.phone_number?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid phone number
            </p>
          )}
        </div>
        <div>
          <label className="label pb-1">First Name:</label>
          <Input
            {...register('given_name', {
              required: true,
              pattern: /^[A-Za-z]+$/i
            })}
            className="border-1 border-slate-200 bg-white w-full"
            type="text"
            name="given_name"
          />
          {errors.given_name?.type === 'required' && (
            <p className="text-red-500" role="alert">
              First name is required
            </p>
          )}
          {errors.given_name?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid first name
            </p>
          )}
        </div>
        <div>
          <label className="label pb-1">Last Name:</label>
          <Input
            {...register('family_name', {
              required: true,
              pattern: /^[A-Za-z]+$/i
            })}
            className="border-1 border-slate-200 bg-white w-full"
            type="text"
            name="family_name"
          />
          {errors.family_name?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Last name is required
            </p>
          )}
          {errors.family_name?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid last name
            </p>
          )}
        </div>
        <div>
          <label className="label pb-1">Password:</label>
          <Input
            {...register('password', {required: true})}
            className="border-1 border-slate-200 bg-white w-full"
            type="password"
            name="password"
          />
          {errors.password?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Password is required
            </p>
          )}
        </div>
        <Button color="secondary" className="w-full mt-8" type="submit">
          create account
        </Button>
        <div className={clsx({hidden: !isSigningUp})}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
      </form>
    </div>
  )
}

export default CreateMemberForm
