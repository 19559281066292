import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getConfig} from './config'
import {getUserToken} from './token'
import {getLogger} from './logging'
import {
  GuestCredentials,
  ReservationCheckinParams,
  ReservationParams
} from '../types'

const {guestPortalApiUrl} = getConfig()
const logger = getLogger('guestPortalApi')

export const guestPortalApi = createApi({
  reducerPath: 'guestPortalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: guestPortalApiUrl,
    prepareHeaders: async (headers): Promise<Headers> => {
      try {
        // injects authorization token into request headers
        const token = getUserToken()
        if (token?.length) {
          headers.set('Authorization', `Bearer ${token}`)
        }
      } catch (e) {
        logger.error({
          message: 'Error occurred setting guest token on HTTP request',
          errorData: {
            error: e
          }
        })
      }
      return headers
    }
  }),
  tagTypes: [],
  endpoints: builder => ({
    cancelReservation: builder.mutation({
      query(reservationId: string) {
        return {
          url: `/cancel-reservation?reservationId=${reservationId}`,
          method: 'PUT'
        }
      },
      invalidatesTags: []
    }),
    postOTAReservation: builder.mutation({
      query({confirmationCode, lastName, ota}) {
        return {
          url: `/reservations/link-ota-reservation`,
          method: 'post',
          body: {
            confirmationCode,
            lastName,
            ota
          }
        }
      },
      invalidatesTags: []
    }),
    getMemberReservations: builder.query({
      query({reservationStatus}) {
        return `/reservations?reservationStatus=${reservationStatus}`
      },
      providesTags: []
    }),
    getContactInfo: builder.query({
      query({}) {
        return `/contact`
      },
      providesTags: []
    }),
    postContactNotificationPreferences: builder.mutation({
      query({smsOptOut, marketingEmailUnsubscribed}) {
        return {
          url: `/contact/notifications`,
          method: 'POST',
          body: {
            smsOptOut,
            marketingEmailUnsubscribed
          }
        }
      },
      invalidatesTags: []
    }),
    getCMSProperties: builder.query({
      query({}) {
        return `/cms/properties`
      },
      transformResponse: (response: any) => {
        console.log('transform response', response)
        return response.listProperties.data.reduce(
          (acc: any, property: any) => {
            acc[property.propertyApaleoId] = property
            return acc
          },
          {}
        )
      },
      providesTags: []
    }),
    getPaymentMethods: builder.query({
      query({reservation}) {
        return `/payment-methods?propertyId=${reservation.propertyId}`
      },
      providesTags: []
    }),
    getPaymentSession: builder.query({
      query({reservation}) {
        return {
          url: `/payment-session`,
          method: 'POST',
          body: {
            reservationId: reservation.reservationId,
            bookingId: reservation.bookingId,
            propertyId: reservation.propertyId,
            returnUrl: 'http://www.example.com',
            countryCode: 'US'
          }
        }
      },
      providesTags: []
    })
  })
})

export const {
  useCancelReservationMutation,
  usePostOTAReservationMutation,
  useGetMemberReservationsQuery,
  useGetCMSPropertiesQuery,
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery,
  useGetContactInfoQuery,
  usePostContactNotificationPreferencesMutation
} = guestPortalApi
