export enum CheckinStep {
  FindReservation = 'FindReservation',
  VerifyIdentity = 'VerifyIdentity',
  VerifyPayment = 'VerifyPayment',
  ViewRoomDetails = 'ViewRoomDetails',
  CompleteCheckin = 'CompleteCheckin'
}

export enum GuestCredentialsType {
  ConfirmationCode = 'ConfirmationCode',
  ReservationLookup = 'ReservationLookup'
}

export interface ReservationLookupCredentials {
  type: GuestCredentialsType
  firstName: string
  lastName: string
  phoneLast4: string
  checkinDate: string
  checkoutDate: string
}

export interface ConfirmationCodeCredentials {
  type: GuestCredentialsType
  confirmationCode: string
  lastName: string
}

export type GuestCredentials =
  | ReservationLookupCredentials
  | ConfirmationCodeCredentials

export enum AutohostVerificationStatus {
  Pending = 'pending',
  Review = 'review',
  Verified = 'verified',
  Rejected = 'rejected',
  Incomplete = 'incomplete'
}

export enum CheckinErrorCode {
  CheckinNotFound = 'CheckinNotFound',
  CheckinDateInvalid = 'CheckinDateInvalid',
  CheckinPropertyInvalid = 'CheckinPropertyInvalid',
  IdentityVerificationInvalid = 'IdentityVerificationInvalid',
  IdentityVerificationInReview = 'IdentityVerificationInReview',
  PinNotAssigned = 'PinNotAssigned',
  ReservationNotFound = 'ReservationNotFound',
  ReservationCheckedIn = 'ReservationCheckedIn',
  ReservationCheckedOut = 'ReservationCheckedOut',
  ReservationCanceled = 'ReservationCanceled',
  ReservationNoShow = 'ReservationNoShow',
  ReservationInvalidStatus = 'ReservationInvalidStatus',
  UnitNotAssigned = 'UnitNotAssigned'
}

export interface CheckinAccess {
  action: CheckinAction
  code?: string
}

export enum CheckinAction {
  Allow = 'Allow',
  Deny = 'Deny'
}

export interface CheckinDetails {
  autohostVerificationStatus: AutohostVerificationStatus
  autohostVerificationUrl: string
  checkinStatus: string
  roomNumber: string
  roomPin: string
  guestPhone: string
}

export interface CheckinInfo {
  checkinDate: string
  checkoutDate: string
  autohostVerificationStatus: AutohostVerificationStatus
  autohostVerificationUrl: any
  checkinStatus: string
  isPinAccess?: boolean
}

export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export enum CheckinStatus {
  Confirmed = 'Confirmed',
  InHouse = 'InHouse',
  CheckedOut = 'CheckedOut',
  Canceled = 'Canceled',
  NoShow = 'NoShow'
}

export interface Reservation {
  id: string
  bookingId: string
  status: CheckinStatus
  paymentAccount?: {
    accountNumber: string
    accountHolder: string
    expiryMonth?: string
    expiryYear?: string
    paymentMethod: string
    payerEmail: string
    isActive: boolean
  }
  property: {
    id: string
    code: string
    name: string
    description: string
  }
  ratePlan: {
    id: string
    code: string
    name: string
    description: string
    isSubjectToCityTax: boolean
  }
  unit: any
  unitGroup: {
    id: string
    code: string
    name: string
    description: string
    type: string
  }
  totalGrossAmount: {
    amount: number
    currency: string
  }
  arrival: string
  departure: string
  created: string
  modified: string
  adults: number
  channelCode: string
  primaryGuest: {
    firstName: string
    lastName: string
    email: string
  }
  guaranteeType: string
  cancellationFee: {
    id: string
    code: string
    name: string
    description: string
    dueDateTime: string
    fee: {
      amount: number
      currency: string
    }
  }
  noShowFee: {
    id: string
    code: string
    name: string
    description: string
    fee: {
      amount: number
      currency: string
    }
  }
  balance: {
    amount: number
    currency: string
  }
  allFoliosHaveInvoice: boolean
  hasCityTax: boolean
}

export interface ReservationParams {
  propertyId: string
  reservationId: string
}

export interface ReservationCheckinParams {
  checkinPropertyId: string
  propertyId: string
  reservationId: string
}

export interface ReservationContext {
  propertyId: string
  bookingId: string
  reservationId: string
  isConfirmed: boolean
}

export interface ReservationCheckin {
  reservation: Reservation
  checkinDetails: CheckinDetails
  checkinAccess: CheckinAccess
}

export enum UserRole {
  Guest = 'Guest',
  Member = 'Member',
  Booker = 'Booker'
}

export interface User {
  isAuthenticated: boolean
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  emailVerified: boolean
  phoneNumberVerified: boolean
}

export interface AuthenticatedUser extends User {
  role: UserRole
  email: string
  firstName: string
  lastName: string
  emailVerified: boolean
  phoneNumberVerified: boolean
  phoneNumber: string
}

export interface GuestUser extends AuthenticatedUser {
  role: UserRole.Guest
  propertyId: string
  bookingId: string
  reservationId: string
}

export interface BookerUser extends AuthenticatedUser {
  role: UserRole.Booker
}

export interface MemberUser extends AuthenticatedUser {
  role: UserRole.Member
}
