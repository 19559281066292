import {confirmSignUp} from 'aws-amplify/auth'
import React from 'react'
import {Button} from 'react-daisyui'
import {useForm} from 'react-hook-form'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'

export const ConfirmSignUpCodeForm = ({
  username,
  onConfirmed
}: {
  username: string
  onConfirmed?: Function
}) => {
  const [isConfirming, setIsConfirming] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch
  } = useForm()

  const handleConfirmCode = async (data: any): Promise<void> => {
    console.log('confirming code', data)
    setIsConfirming(true)
    try {
      const {isSignUpComplete, nextStep} = await confirmSignUp({
        username,
        ...data
      })
      if (onConfirmed) {
        onConfirmed({nextStep, data})
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred')
    } finally {
      setIsConfirming(false)
    }
  }

  return (
    <form
      className="grid grid-cols-1 gap-6"
      onSubmit={handleSubmit(handleConfirmCode)}
    >
      {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
      <div>
        <label className="label pt-6 pb-1">Enter Verification Code</label>
        <input
          {...register('confirmationCode', {required: true})}
          type="text"
          name="confirmationCode"
          className="border-1 border-slate-200 bg-white w-full"
          required
        />
      </div>
      {errors.confirmationCode?.type === 'required' && (
        <p className="text-red-500" role="alert">
          Code is required
        </p>
      )}
      <div>
        <Button type="submit" className="bg-cabana-500 text-white w-full">
          Confirm
        </Button>
      </div>
      <div className={clsx({hidden: !isConfirming})}>
        <LoadingSpinner size={LoadingSpinnerSize.Small} />
      </div>
    </form>
  )
}
