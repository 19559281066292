import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import SendResetPasswordCodeForm from './SendResetPasswordCodeForm'
import ConfirmPasswordResetForm from './ConfirmPasswordResetForm'

enum ResetPasswordStep {
  SEND_CODE,
  CONFIRM_RESET_PASSWORD_WITH_CODE,
  RESET_PASSWORD,
  RESET_SUCCESS
}
export const ResetPasswordCode = ({
  onResetSuccess
}: {
  onResetSuccess?: Function
}) => {
  const [userData, setUserData] = useState({
    username: ''
  })

  const [step, setStep] = useState(ResetPasswordStep.SEND_CODE)

  {
    switch (step) {
      case ResetPasswordStep.SEND_CODE:
        return (
          <SendResetPasswordCodeForm
            onCodeSent={({
              response: {nextStep},
              data: {email}
            }: {
              response: {nextStep: {resetPasswordStep: string}}
              data: {email: string}
            }) => {
              setUserData({...userData, username: email})
              if (
                nextStep.resetPasswordStep ===
                'CONFIRM_RESET_PASSWORD_WITH_CODE'
              ) {
                setStep(ResetPasswordStep.CONFIRM_RESET_PASSWORD_WITH_CODE)
              }
            }}
          />
        )
      case ResetPasswordStep.CONFIRM_RESET_PASSWORD_WITH_CODE:
        return (
          <div>
            <ConfirmPasswordResetForm
              username={userData.username}
              onConfirmed={() => {
                setStep(ResetPasswordStep.RESET_SUCCESS)
              }}
            />
          </div>
        )
      case ResetPasswordStep.RESET_SUCCESS:
        return (
          <div>
            <FontAwesomeIcon icon="check-circle" />
            Password reset successfully
          </div>
        )
    }
  }
}

export default ResetPasswordCode
