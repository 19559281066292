import React, {useState} from 'react'
import {getLogger} from '../../services/logging'
import {useNavigate} from 'react-router-dom'
import CreateMemberAccount from '../account/CreateMemberAccount'

const logger = getLogger('MemberLoginForm')

const MemberLoginForm = () => {
  const navigate = useNavigate()
  const onResetSuccess = () => {
    navigate('/login')
  }

  return <>{/* <CreateMemberAccount /> */}</>
}

export default MemberLoginForm
