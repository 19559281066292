import {createApi} from '@reduxjs/toolkit/query/react'
import {graphqlRequestBaseQuery} from '@rtk-query/graphql-request-base-query'
import {getUrl, list} from 'aws-amplify/storage'

export const publicAssetsS3 = createApi({
  reducerPath: 'publicAssetsS3',
  baseQuery: graphqlRequestBaseQuery({
    url: ''
  }),
  endpoints: builder => ({
    getPublicUnitGroupImages: builder.query({
      async queryFn({propertyCode, unitGroupId}) {
        try {
          const result: any = await list({
            path: `public/unitgroups/${propertyCode}/${unitGroupId}/`
          })

          const images = result.items.map(({path}: {path: string}) => ({
            key: path
          }))

          return {
            data: {images}
          }
        } catch (error) {
          return {error}
        }
      }
    }),
    getPublicPropertyImages: builder.query({
      async queryFn(propertyCode) {
        try {
          const result: any = await list({
            path: `public/properties/${propertyCode}/`
          })

          const images = result.items.map(({path}: {path: string}) => ({
            key: path
          }))

          console.log('images', images)
          return {
            data: images
          }
        } catch (error) {
          console.log('Error ', error)
          return {error}
        }
      }
    }),
    getS3SignedUrl: builder.query({
      async queryFn(path) {
        try {
          console.log('path', path)
          const result: any = await getUrl({path, options: {expiresIn: 300}})

          console.log('signed image result', result)
          return {
            data: result.url
          }
        } catch (error) {
          console.log('Error ', error)
          return {error}
        }
      }
    })
  })
})

export const {
  useGetPublicUnitGroupImagesQuery,
  useGetPublicPropertyImagesQuery,
  useGetS3SignedUrlQuery
} = publicAssetsS3
