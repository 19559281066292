import React, {useEffect, useState} from 'react'
import StayCard from './StayCard'
import ManageReservation from './ManageReservation'
import {
  useGetCMSPropertiesQuery,
  useGetMemberReservationsQuery
} from '../../services/guestPortalApi'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {Button, Modal} from 'react-daisyui'
import AddOTAReservationForm from './AddOTAReservationForm'

const MemberStays = () => {
  const stays = [
    {
      cancellationPolicy: 'Free cancellation until check-in',
      checkinDate: 'June 12, 2025',
      checkoutDate: 'June 25, 2025',
      reservation_status: 'Confirmed',
      unitType: '1 Bedroom',
      id: '1234',
      propertyPhone: '',
      propertyTitle: 'Placemakr Property 1',
      confirmationCode: '1234',
      propertyId: 'TEST',
      reservationId: '1234-1',
      firstName: '',
      lastName: '',
      email: '',
      phoneLast4: '',
      propertyAddress: '',
      bookingId: '1234',
      propertySMSPhone: '',
      preCheckUrl: '',
      pmStayInfoLink: ''
    }
  ]

  const staysQuery = useGetMemberReservationsQuery({
    reservationStatus: 'Confirmed,InHouse'
  })
  const canceledStaysQuery = useGetMemberReservationsQuery({
    reservationStatus: 'Canceled,NoShow'
  })
  const pastStaysQuery = useGetMemberReservationsQuery({
    reservationStatus: 'CheckedOut'
  })

  const propertiesQuery = useGetCMSPropertiesQuery({})

  const [manageStay, setManageStay] = useState(false)
  const [currentStay, setCurrentStay] = useState(stays[1])

  const onManageStay = (stay: any) => {
    setManageStay(true)
    setCurrentStay(stay)
  }

  const onReturnToStaysClick = () => setManageStay(false)

  const {Dialog, handleShow} = Modal.useDialog()
  const [
    showAddOTAReservationDialog,
    setshowAddOTAReservationDialog
  ] = useState(false)

  return (
    <div className="rows gap-4 space-y-6">
      <Dialog
        open={showAddOTAReservationDialog}
        onClose={() => setshowAddOTAReservationDialog(false)}
        className="modal-box bg-white max-w-sm"
      >
        <AddOTAReservationForm></AddOTAReservationForm>
        <form method="dialog">
          <Button className="btn btn-link no-underline text-sm w-full">
            cancel
          </Button>
        </form>
        {/* <ResetPasswordCode username={user.email}></ResetPasswordCode> */}
      </Dialog>
      <div>
        Did you book with a third party? Add your reservation
        <span>
          <a
            className="cursor-pointer text-xs lowercase font-thin"
            onClick={() => setshowAddOTAReservationDialog(true)}
            color="secondary"
          >
            here
          </a>
        </span>
      </div>
      {manageStay && propertiesQuery.data ? (
        <ManageReservation
          reservation={currentStay}
          onMyStaysClick={onReturnToStaysClick}
          property={
            propertiesQuery.data
              ? propertiesQuery.data[currentStay.propertyId]
              : null
          }
        ></ManageReservation>
      ) : (
        <div>
          <div>
            <h1 className="text-xl font-semibold">Active Reservations</h1>
            {staysQuery.isLoading && (
              <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
            )}

            {staysQuery.data?.map((stay: any, index: number) => (
              <div key={`stay-${stay.reservationId}`} className="max-w-[600px]">
                <StayCard
                  stay={stay}
                  property={
                    propertiesQuery.data
                      ? propertiesQuery.data[stay.propertyId]
                      : null
                  }
                  onManageStay={onManageStay}
                ></StayCard>
              </div>
            ))}
          </div>
          <div>
            <h1 className="text-xl font-semibold">Past Reservations</h1>
            {pastStaysQuery.isLoading && (
              <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
            )}
            {pastStaysQuery.data?.map((stay: any, index: number) => (
              <div key={`stay-${stay.reservationId}`} className="max-w-[600px]">
                <StayCard
                  stay={stay}
                  property={
                    propertiesQuery.data
                      ? propertiesQuery.data[stay.propertyId]
                      : null
                  }
                  onManageStay={onManageStay}
                ></StayCard>
              </div>
            ))}
          </div>
          <div>
            <h1 className="text-xl font-semibold">Canceled Reservations</h1>
            {canceledStaysQuery.isLoading && (
              <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
            )}
            {canceledStaysQuery.data?.map((stay: any, index: number) => (
              <div key={`stay-${stay.reservationId}`} className="max-w-[600px]">
                <StayCard
                  stay={stay}
                  property={
                    propertiesQuery.data
                      ? propertiesQuery.data[stay.propertyId]
                      : null
                  }
                  onManageStay={onManageStay}
                ></StayCard>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default MemberStays
