import React from 'react'
import {Button, Card} from 'react-daisyui'
import {useGetPublicPropertyImagesQuery} from '../../services/publicAssetsS3Api'
import {StorageImage} from '@aws-amplify/ui-react-storage'
import {getUserToken} from '../../services/token'
import S3Image from '../common/S3Image'
import {useGetCMSPropertiesQuery} from '../../services/guestPortalApi'
import {formatEndDate, formatStartDate} from '../../services/utils'
import {UserIcon} from '@heroicons/react/16/solid'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser} from '@fortawesome/free-solid-svg-icons'
const StayCard = ({
  stay,
  property,
  onManageStay
}: {
  stay: any
  property?: any
  onManageStay?: Function
}) => {
  const token = getUserToken()

  const propertiesQuery = useGetCMSPropertiesQuery({})

  return (
    <Card className="md:card-side bg-white shadow-md max-w-lg">
      {propertiesQuery.data && propertiesQuery.data[stay.propertyId] ? (
        <Card className="md:card-side bg-white shadow-md max-w-lg">
          <Card.Image
            src={propertiesQuery.data[stay.propertyId].location.images[0]}
            alt="Shoes"
            // className='max-w-[200px] h-[100px]'
            className="w-full h-full object-cover"
          />
          <Card.Body className="p-4 min-w-[300px]">
            <p
              className="text-xs uppercase font-semibold btn-cabana"
              color="secondary"
            >
              {propertiesQuery.data[stay.propertyId].location?.locationName ||
                ''}
            </p>
            <Card.Title tag="h1" className="font-semibold text-lg">
              {stay.property?.name}
            </Card.Title>
            <div className="container mx-auto text-sm font-normal">
              <p>
                {formatStartDate(stay.checkinDate)} -{' '}
                {formatEndDate(stay.checkoutDate)}
              </p>
              <ul>
                <li>
                  <p>
                    <span className="pr-2">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-xs text-primary"
                      />
                    </span>
                    {stay.adults} guest{stay.adults > 1 ? 's' : ''}
                  </p>
                </li>
                <li>
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-xs text-primary"
                    />
                  </span>
                  {stay.unitType}
                </li>
                <li>
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-xs text-primary"
                    />
                  </span>
                  {stay.reservationId}
                </li>
              </ul>
            </div>

            <Card.Actions className="justify-end pt-5">
              <div className="container flex justify-end pt-25">
                <Button
                  className="btn-sm text-xs lowercase font-thin"
                  color="secondary"
                  onClick={evt => {
                    if (onManageStay) {
                      onManageStay(stay)
                    }
                  }}
                >
                  Manage Reservation
                </Button>
              </div>
            </Card.Actions>
          </Card.Body>
        </Card>
      ) : (
        <p>{JSON.stringify(propertiesQuery.data)}</p>
      )}
    </Card>
  )
}

export default StayCard
