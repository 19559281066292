import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction
} from '@reduxjs/toolkit'
import {getConfig} from '../services/config'
import {cognitoApi} from '../services/cognitoApi'
import {guestCheckinApi} from '../services/guestCheckinApi'
import appReducer from './appSlice'
import userReducer from './userSlice'
import {getLogger} from '../services/logging'
import {publicAssetsS3} from '../services/publicAssetsS3Api'
import {guestPortalApi} from '../services/guestPortalApi'

const {reduxDevTools} = getConfig()

const logger = getLogger('ReduxStore')

const combinedReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  [guestCheckinApi.reducerPath]: guestCheckinApi.reducer,
  [guestPortalApi.reducerPath]: guestPortalApi.reducer,
  [cognitoApi.reducerPath]: cognitoApi.reducer,
  [publicAssetsS3.reducerPath]: publicAssetsS3.reducer
})

let rootState: RootState

const isSignOut = ({type, payload}: AnyAction) =>
  type === 'auth/setAuthenticated' && payload === false

const defaultRootState = {
  app: {
    isInitialized: true
  },
  user: {
    isAuthenticated: false
  }
} as RootState

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  // sign out
  if (isSignOut(action)) {
    state = defaultRootState
  }
  rootState = combinedReducer(state, action)

  // log all changes to redux state
  logger.debug({
    message: 'Redux state changed',
    data: {action, state: rootState, previousState: state}
  })

  return rootState
}

export function getRootState() {
  return rootState
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware()
      .concat([guestCheckinApi.middleware])
      .concat([guestPortalApi.middleware])
      .concat([cognitoApi.middleware])
      .concat([publicAssetsS3.middleware])
  },
  devTools: reduxDevTools
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
