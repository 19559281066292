import React, {useState} from 'react'
import {Button, Input} from 'react-daisyui'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'
import {useForm} from 'react-hook-form'
import {signUp} from 'aws-amplify/auth'
import {usePostOTAReservationMutation} from '../../services/guestPortalApi'

export const AddOTAReservationForm = ({
  onSignUpSuccess
}: {
  onSignUpSuccess?: Function
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch
  } = useForm()
  const [isSearching, setisSearching] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [
    postOTAReservation,
    postOTAReservationResult
  ] = usePostOTAReservationMutation()

  const handleSignUp = async (data: any) => {
    event.preventDefault()
    setisSearching(true)
    console.log('data:', data)
    postOTAReservation(data)
    try {
      if (onSignUpSuccess) {
        // onSignUpSuccess({nextStep, data})
        console.log('reservations:', postOTAReservationResult)
      }
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setisSearching(false)
    }
  }

  return (
    <div>
      {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
      <form onSubmit={handleSubmit(handleSignUp)}>
        <div>
          <label className="label pb-1">OTA</label>
          <select
            {...register('ota', {required: true})}
            className="select select-bordered w-full"
            name="ota"
          >
            <option disabled selected>
              Select one
            </option>
            <option value={'Airbnb'}>Airbnb</option>
            <option value={'Booking'}>Booking.com</option>
            <option value={'Expedia'}>Expedia</option>
          </select>
          {errors.ota?.type === 'required' && (
            <p className="text-red-500" role="alert">
              OTA is required
            </p>
          )}
        </div>

        <div>
          <label className="label pb-1">Confirmation code:</label>
          <Input
            {...register('confirmationCode', {
              required: true
            })}
            className="border-1 border-slate-200 bg-white w-full"
            type="text"
            name="confirmationCode"
          />
          {errors.confirmationCode?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Confirmation code is required
            </p>
          )}
        </div>

        <div>
          <label className="label pb-1">Last name on reservation:</label>
          <Input
            {...register('lastName', {
              required: true,
              pattern: /^[A-Za-z]+$/i
            })}
            className="border-1 border-slate-200 bg-white w-full"
            type="text"
            name="lastName"
          />
          {errors.lastName?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Last name is required
            </p>
          )}
          {errors.lastName?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid last name
            </p>
          )}
        </div>
        <Button color="secondary" className="w-full mt-8" type="submit">
          import reservation information
        </Button>
        <div className={clsx({hidden: !isSearching})}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
      </form>
    </div>
  )
}

export default AddOTAReservationForm
