import Cookies from 'js-cookie'
import {AuthenticatedUser, GuestUser, MemberUser, UserRole} from '../types'
import {getConfig} from './config'

export interface TokenPayload {
  iss: string
  sub: string
  exp: number
  iat: number
}

export interface GuestTokenPayload extends TokenPayload {
  first_name: string
  last_name: string
  property_id: string
  booking_id: string
  reservation_id: string
  role: string
}

export interface CognitoTokenPayload extends TokenPayload {
  email: string
  given_name: string
  family_name: string
  email_verified: boolean
  phone_number: string
  phone_number_verified: boolean
}

const {cognitoRegion, cognitoUserPoolId, guestTokenIssuer} = getConfig()
export const USER_TOKEN_COOKIE = 'user-token'

export function getUserToken(): string {
  return Cookies.get(USER_TOKEN_COOKIE)
}

export function setUserToken(token: string): void {
  Cookies.set(USER_TOKEN_COOKIE, token)
}

export function removeUserToken(): void {
  Cookies.remove(USER_TOKEN_COOKIE)
}

export function parseJwt<T extends TokenPayload>(token: string): T {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function getUser(token: string): AuthenticatedUser {
  const cognitoTokenIssuer = `https://cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`

  const tokenPayload = parseJwt(token)
  if (tokenPayload.iss === cognitoTokenIssuer) {
    console.log('cognitoTokenPayload', tokenPayload)
    const {
      email,
      given_name,
      family_name,
      email_verified,
      phone_number,
      phone_number_verified
    } = tokenPayload as CognitoTokenPayload
    return {
      role: UserRole.Member,
      isAuthenticated: true,
      email,
      firstName: given_name,
      lastName: family_name,
      phoneNumber: phone_number,
      emailVerified: email_verified,
      phoneNumberVerified: phone_number_verified
    } as MemberUser
  } else if (tokenPayload.iss === guestTokenIssuer) {
    const {
      sub,
      first_name,
      last_name,
      reservation_id,
      booking_id
    } = tokenPayload as GuestTokenPayload
    return {
      role: UserRole.Guest,
      isAuthenticated: true,
      email: sub,
      firstName: first_name,
      lastName: last_name,
      reservationId: reservation_id,
      bookingId: booking_id
    } as GuestUser
  } else {
    throw new Error('Invalid token issuer')
  }
}
